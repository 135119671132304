import React, { useEffect, useState } from "react";
import Card from "components/card";
import apiClient from "./../axiosInstance";
import InputField from "../../../components/fields/InputField";

const Cars = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get("/cars");
                setCars(response.data || []);
            } catch (err) {
                setError("Failed to load car data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (index, field, value) => {
        setCars(prevCars => {
            const updatedCars = [...prevCars];
            updatedCars[index][field] = Number(value);
            return updatedCars;
        });
    };

    const handleSave = async (car) => {
        try {
            await apiClient.put(`/cars/${car.CarID}`, {
                MinAge: car.MinAge,
                MaxAge: car.MaxAge,
                Quantity: car.Quantity,
                ExcessDamage: car.ExcessDamage,
                Deposit: car.Deposit
            });
            alert("Car details updated successfully!");
        } catch (error) {
            alert("Failed to update car details.");
        }
    };

    if (loading) return <div className="flex justify-center items-center h-screen">Loading car data...</div>;
    if (error) return <div className="text-red-500 flex justify-center items-center h-screen">{error}</div>;

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                {cars.length === 0 ? (
                    <Card extra="p-5 mb-1 flex h-auto">
                        <div className="flex justify-center items-center w-full h-full">
                            <p className="text-lg font-semibold">No cars available.</p>
                        </div>
                    </Card>
                ) : (
                    cars.map((car, index) => (
                        <Card key={car.CarID} extra="p-5 mb-1 flex h-auto">
                            <div className="flex flex-col md:flex-row w-full">
                                {/* Car Image */}
                                <div className="w-full md:w-1/2 h-auto flex-shrink-0 overflow-hidden">
                                    <img
                                        src={car.imageURL}
                                        alt={`${car.Make} ${car.Model}`}
                                        className="w-full h-auto"
                                        style={{ objectFit: "contain" }}
                                    />
                                    {/* Title Section */}
                                    <div className="text-center mb-5">
                                        <span className="block font-bold text-lg">
                                            {car.Make} {car.Model}
                                        </span>
                                        <span className="block">
                                            {car.Transmission} | {car.FuelType}
                                            {car.CompanyName && (
                                                <span className="block text-gray-600 italic">
                                                    Company: {car.CompanyName}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col w-full md:w-1/2 p-3">
                                    {/* Details Section in Three Columns */}
                                    <div className="grid grid-cols-3 gap-1">
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Doors</span>
                                            <span>{car.Doors}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Seats</span>
                                            <span>{car.Seats}</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Size</span>
                                            <span>{car.Size}</span>
                                        </div>

                                        {/* Editable Fields */}
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Min Age</span>
                                            <InputField
                                                type="number"
                                                value={car.MinAge ?? ""}
                                                onChange={(e) => handleInputChange(index, "MinAge", e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Max Age</span>
                                            <InputField
                                                type="number"
                                                value={car.MaxAge ?? ""}
                                                onChange={(e) => handleInputChange(index, "MaxAge", e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Quantity</span>
                                            <InputField
                                                type="number"
                                                value={car.Quantity ?? ""}
                                                onChange={(e) => handleInputChange(index, "Quantity", e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Excess</span>
                                            <InputField
                                                type="number"
                                                value={car.ExcessDamage ?? ""}
                                                onChange={(e) => handleInputChange(index, "ExcessDamage", e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">Deposit</span>
                                            <InputField
                                                type="number"
                                                value={car.Deposit ?? ""}
                                                onChange={(e) => handleInputChange(index, "Deposit", e.target.value)}
                                            />
                                        </div>

                                        {/* Save Button */}
                                        <div className="flex flex-col items-center mt-8">
                                            <button
                                                onClick={() => handleSave(car)}
                                                className="bg-brand-500 hover:bg-brand-600 text-white px-4 py-2 rounded-md"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
        </div>
    );
};

export default Cars;
