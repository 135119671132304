import React, { useEffect, useState } from 'react';
import PieChartCard from "views/admin/dashboard/components/PieChartCard";
import { FaDollarSign, FaChartLine, FaReceipt, FaBell, FaCheckCircle } from 'react-icons/fa';
import Widget from "components/widget/Widget";
import apiClient from './../axiosInstance';

const Dashboard = () => {
    const [, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [confirmedReservations, setConfirmedReservations] = useState(0);
    const [newReservations, setNewReservations] = useState(0);
    const [Administrative, setAdministrative] = useState(0);
    const [Broker, setBroker] = useState(0);
    const [Site, setSite] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/reservations');
                setData(response.data);

                const {
                    totalPrice,
                    totalCommission,
                    confirmedReservations,
                    newReservations,
                    Administrative,
                    Broker,
                    Site
                } = response.data.reduce(
                    (acc, reservation) => {
                        
                        if (reservation.Status === "Confirmed") {
                            acc.totalPrice += reservation.TotalAmount;
                            acc.totalCommission += reservation.Commission;
                            acc.confirmedReservations++;
                            if (new Date(reservation.PickupDatetime) > new Date()) {
                                acc.newReservations++;
                            }
                            if (reservation.Channel === "Administrative") {
                                acc.Administrative++;
                            } else if (reservation.Channel === "Broker") {
                                acc.Broker++;
                            } else if (reservation.Channel === "Site") {
                                acc.Site++;
                            }
                        }

                        return acc;
                    },
                    {
                        totalPrice: 0,
                        totalCommission: 0,
                        confirmedReservations: 0,
                        newReservations: 0,
                        Administrative: 0,
                        Broker: 0,
                        Site: 0
                    }
                );

                setTotalPrice(totalPrice);
                setTotalCommission(totalCommission);
                setConfirmedReservations(confirmedReservations);
                setNewReservations(newReservations);
                setAdministrative(Administrative);
                setBroker(Broker);
                setSite(Site);
            } catch (err) {
                console.error('Error fetching reservations:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const Profit = totalPrice - totalCommission;
    const AdministrativePercentage = confirmedReservations > 0 ? ((Administrative / confirmedReservations) * 100).toFixed(2) : 0;
    const BrokerPercentage = confirmedReservations > 0 ? ((Broker / confirmedReservations) * 100).toFixed(2) : 0;
    const SitePercentage = confirmedReservations > 0 ? ((Site / confirmedReservations) * 100).toFixed(2) : 0;

    return (
        <div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
                <Widget
                    icon={<FaDollarSign className="h-7 w-7" />}
                    title={"Total Earnings"}
                    subtitle={totalPrice.toFixed(2)}
                    isMoney={true}
                />
                <Widget
                    icon={<FaChartLine className="h-7 w-7" />}
                    title={"Profit"}
                    subtitle={Profit.toFixed(2)}
                    isMoney={true}
                />
                <Widget
                    icon={<FaReceipt className="h-7 w-7" />}
                    title={"Commission"}
                    subtitle={totalCommission.toFixed(2)}
                    isMoney={true}
                />
                <Widget
                    icon={<FaBell className="h-7 w-7" />}
                    title={"Pending Rsrv."}
                    subtitle={newReservations}
                    isMoney={false}
                />
                <Widget
                    icon={<FaCheckCircle className="h-7 w-7" />}
                    title={"Total Rsrv."}
                    subtitle={confirmedReservations}
                    isMoney={false}
                />
            </div>

            <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                <PieChartCard
                    AdministrativePercentage={AdministrativePercentage}
                    BrokerPercentage={BrokerPercentage}
                    SitePercentage={SitePercentage}
                />
            </div>
        </div>
    );
};

export default Dashboard;
