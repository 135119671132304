import React from 'react';
import PageSizeSelector from 'components/pageSizeSelector';
import Dropdown from 'components/dropdown/Dropdown';
import Checkbox from 'components/checkbox';

const FilterSection = ({
    selectedPickupStations = [],
    setSelectedPickupStations,
    selectedReturnStations = [],
    setSelectedReturnStations,
    selectedCars = [],
    setSelectedCars,
    pageSize,
    setPageSize,
    setCurrentPage,
    stationsData = [],
    carsData = [],
    selectedStatuses = [],
    setSelectedStatuses,
}) => {

    // Function to toggle pickup station selection
    const togglePickupStationSelection = (stationName) => {
        setSelectedPickupStations((prevSelected) => {
            if (prevSelected.includes(stationName)) {
                return prevSelected.filter((name) => name !== stationName);
            } else {
                return [...prevSelected, stationName];
            }
        });
        setCurrentPage(0);
    };

    // Function to toggle return station selection
    const toggleReturnStationSelection = (stationName) => {
        setSelectedReturnStations((prevSelected) => {
            if (prevSelected.includes(stationName)) {
                return prevSelected.filter((name) => name !== stationName);
            } else {
                return [...prevSelected, stationName];
            }
        });
        setCurrentPage(0);
    };

    // Function to toggle car selection using CarID
    const toggleCarSelection = (CarID) => { 
        setSelectedCars((prevSelected) => {
            if (prevSelected.includes(CarID)) {
                return prevSelected.filter((id) => id !== CarID);
            } else {
                return [...prevSelected, CarID];
            }
        });
        setCurrentPage(0);
    };

    // Function to toggle status selection
    const toggleStatusSelection = (status) => {
        setSelectedStatuses((prevSelected) => {
            if (prevSelected.includes(status)) {
                return prevSelected.filter((s) => s !== status);
            } else {
                return [...prevSelected, status];
            }
        });
        setCurrentPage(0);
    };

    const selectedPickupCount = selectedPickupStations.length;
    const selectedReturnCount = selectedReturnStations.length;
    const selectedCarsCount = selectedCars.length;
    const selectedStatusCount = selectedStatuses.length;

    return (
        <div className="flex flex-wrap items-center justify-between mt-4 md:grid-cols-2 gap-4">
            <div className="flex items-center space-x-4">
                {/* Pickup Stations Dropdown */}
                <Dropdown
                    buttonLabel={
                        selectedPickupCount === 0
                            ? 'Pickup Location (All)'
                            : `Pickup Location (${selectedPickupCount})`
                    }
                >
                    <ul className="max-h-80 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-600">
                        {stationsData.map((station) => {
                            const isSelected = selectedPickupStations.includes(station.Address);
                            return (
                                <li key={station.StationID} className="p-2">
                                    <label className="flex items-center space-x-2">
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={() => togglePickupStationSelection(station.Address)}
                                        />
                                        <span className="text-gray-800 dark:text-gray-200">
                                            {station.Address}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </Dropdown>

                {/* Return Stations Dropdown */}
                <Dropdown
                    buttonLabel={
                        selectedReturnCount === 0
                            ? 'Return Location (All)'
                            : `Return Location (${selectedReturnCount})`
                    }
                >
                    <ul className="max-h-80 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-600">
                        {stationsData.map((station) => {
                            const isSelected = selectedReturnStations.includes(station.Address);
                            return (
                                <li key={station.StationID} className="p-2">
                                    <label className="flex items-center space-x-2">
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={() => toggleReturnStationSelection(station.Address)}
                                        />
                                        <span className="text-gray-800 dark:text-gray-200">
                                            {station.Address}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </Dropdown>

                {/* Cars Dropdown */}
                <Dropdown
                    buttonLabel={
                        selectedCarsCount === 0
                            ? 'Car (All)'
                            : `Car (${selectedCarsCount})`
                    }
                >
                    <ul className="max-h-80 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-600">
                        {carsData.map((car) => {
                            const isSelected = selectedCars.includes(car.CarID);
                            return (
                                <li key={car.CarID} className="p-2">
                                    <label className="flex items-center space-x-2">
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={() => toggleCarSelection(car.CarID)}
                                        />
                                        <span className="text-gray-800 dark:text-gray-200">
                                            {`${car.Make} ${car.Model} ${car.Transmission[0]} ${car.FuelType[0]}`}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </Dropdown>

                {/* Status Dropdown */}
                <Dropdown
                    buttonLabel={
                        selectedStatusCount === 0
                            ? 'Status (All)'
                            : `Status (${selectedStatusCount})`
                    }
                >
                    <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                        {['Confirmed', 'Cancelled'].map((status) => {
                            const isSelected = selectedStatuses.includes(status);
                            return (
                                <li key={status} className="p-2">
                                    <label className="flex items-center space-x-2">
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={() => toggleStatusSelection(status)}
                                        />
                                        <span className="text-gray-800 dark:text-gray-200">
                                            {status}
                                        </span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </Dropdown>
            </div>

            <div className="flex items-center">
                <label htmlFor="pageSize" className="mr-2 text-sm">
                    Rows:
                </label>
                <PageSizeSelector
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default FilterSection;
