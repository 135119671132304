import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Card from 'components/card';
import apiClient from './../axiosInstance';
import Checkbox from "components/checkbox";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Button from "components/button/Button";
import { MdKeyboardArrowLeft } from "react-icons/md";
import TooltipWrapper from './TooltipWrapper';

const StationDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [station, setStation] = useState(null);
    const [cars, setCars] = useState([]);
    const [error, setError] = useState(null);
    const [isModified, setIsModified] = useState(false);
    const [loading, setLoading] = useState(true);

    const [fromHour, setFromHour] = useState({ hour: 9, minute: 0 });
    const [toHour, setToHour] = useState({ hour: 20, minute: 0 });

    // Helper function to format time to the server format (ISO string) in UTC
    const formatToISO = (hour, minute) => {
        const date = new Date(Date.UTC(1970, 0, 1, hour, minute));
        return date.toISOString(); // This will return the time in UTC format
    };

    // Helper function to convert a UTC string to local time for display
    const parseUTC = (utcString) => {
        const date = new Date(utcString);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        return { hour: hours, minute: minutes };
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`/company-location-with-car/${id}`);

                if (response.data.success) {
                    const { station, cars } = response.data.data;
                    setStation(station);
                    setCars(cars);

                    // Parse FromHour and ToHour from the UTC response and update state
                    const fromUTC = parseUTC(station.FromHour);
                    const toUTC = parseUTC(station.ToHour);

                    setFromHour(fromUTC);
                    setToHour(toUTC);

                } else {
                    setError('Failed to load station data.');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to load data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleCheckboxChange = (carID) => {
        const updatedCars = cars.map(car => {
            if (car.CarID === carID) {
                return { ...car, isAvailableAtStation: !car.isAvailableAtStation };
            }
            return car;
        });
        setCars(updatedCars);
        setIsModified(true);
    };

    const handleInputChange = (field, value) => {
        setStation((prev) => ({ ...prev, [field]: value }));
        setIsModified(true);
    };

    // Function to update FromHour and ToHour based on time input change
    const handleTimeChange = (type, timePart, value) => {
        if (type === 'from') {
            setFromHour(prev => ({
                ...prev,
                [timePart]: value
            }));
        } else if (type === 'to') {
            setToHour(prev => ({
                ...prev,
                [timePart]: value
            }));
        }
        setIsModified(true);
    };

    const updateLocation = async () => {
        try {
            const carIDs = cars
                .filter((car) => car.isAvailableAtStation)
                .map((car) => car.CarID);
            console.log(carIDs);
            // Update the station with the formatted FromHour and ToHour in UTC format
            await apiClient.put(`/update-location/${station.CompanyStationID}`, {
                station: {
                    ...station,
                    FromHour: formatToISO(fromHour.hour, fromHour.minute),
                    ToHour: formatToISO(toHour.hour, toHour.minute)
                },
                carIDs
            });
            setIsModified(false);
            alert("Details Saved successfully");
        } catch (err) {
            console.error('Error updating location data:', err);
            setError('Failed to update location data. Please try again later.');
        }
    };

    const formatToTwoDigits = (value) => {
        return String(value).padStart(2, '0');
    };

    // Determine if all cars are selected
    const allSelected = cars.length > 0 && cars.every(car => car.isAvailableAtStation);

    // Handle "Select All" checkbox change
    const handleSelectAllChange = () => {
        const newSelectedState = !allSelected;
        const updatedCars = cars.map(car => ({
            ...car,
            isAvailableAtStation: newSelectedState
        }));
        setCars(updatedCars);
        setIsModified(true);
    };

    return (
        <Card extra="p-5 flex flex-col h-auto">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <p>Loading...</p>
                </div>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <>
                    <div className="flex justify-between items-center mb-6">
                        <Button
                            onClick={() => navigate('/admin/locations')}
                            icon={<MdKeyboardArrowLeft size={24} />}
                            label=""
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-4 mb-6">
                        <div className="grid grid-cols-4 gap-4">
                            <InputField label="Address" value={station.Address} disabled />
                            <InputField label="City" value={station.City} disabled />
                            <InputField label="Postal" value={station.PostalCode} disabled />
                            <InputField label="Country" value={station.Country} disabled />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <InputField
                                label="Phone Number"
                                value={station.PhoneNumber}
                                onChange={(e) => handleInputChange('PhoneNumber', e.target.value)}
                            />
                            <InputField
                                type="number"
                                label="Delivery Cost"
                                value={station.DeliveryCost}
                                onChange={(e) => handleInputChange('DeliveryCost', e.target.value)}
                            />
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <InputField
                                label="From Hour"
                                type="number"
                                value={formatToTwoDigits(fromHour.hour)}
                                onChange={(e) => handleTimeChange('from', 'hour', e.target.value)}
                            />
                            <InputField
                                label="From Min"
                                type="number"
                                value={formatToTwoDigits(fromHour.minute)}
                                onChange={(e) => handleTimeChange('from', 'minute', e.target.value)}
                            />
                            <InputField
                                label="To Hour"
                                type="number"
                                value={formatToTwoDigits(toHour.hour)} // Apply padStart here
                                onChange={(e) => handleTimeChange('to', 'hour', e.target.value)}
                            />
                            <InputField
                                label="To Min"
                                type="number"
                                value={formatToTwoDigits(toHour.minute)} // Apply padStart here
                                onChange={(e) => handleTimeChange('to', 'minute', e.target.value)}
                            />
                        </div>
                        <TextField
                            label="Delivery Info"
                            id="delivery-info"
                            placeholder="Enter the delivery info that will be displayed to the customer"
                            cols={30}
                            rows={4}
                            value={station.DeliveryInfo}
                            onChange={(e) => handleInputChange('DeliveryInfo', e.target.value)}
                            tooltip="This will be sent to the customer in order to meet you."
                        />
                    </div>
                    <h3 className="text-xl font-bold mt-6 mb-2">Available Cars</h3>

                    {/* Select All Checkbox */}
                    <div className="flex items-center mb-4">
                        <Checkbox
                            checked={allSelected}
                            onChange={handleSelectAllChange}
                            extra="mr-2"
                        />
                        <span>Select All</span>
                    </div>

                    {/* List of Cars with Individual Checkboxes */}
                    {cars.length > 0 ? (
                        cars.map((car) => (
                            <div key={car.CarID} className="flex items-center mb-2">
                                <Checkbox
                                    checked={car.isAvailableAtStation}
                                    onChange={() => handleCheckboxChange(car.CarID)}
                                    extra="mr-2"
                                />
                                <TooltipWrapper
                                    content="Hover to see the image"
                                    icon="fas fa-image"
                                    imageURL={car.imageURL}
                                    placement="right"
                                />
                                <span className="ml-2">
                                    {car.Make} {car.Model} - {car.Transmission}, {car.FuelType}
                                </span>
                            </div>
                        ))
                    ) : (
                        <p>No cars available.</p>
                    )}

                    <div className="mt-6">
                        <Button
                            onClick={updateLocation}
                            label="Save"
                            disabled={!isModified}
                        />
                    </div>
                </>
            )}
        </Card>
    );
};

export default StationDetails;
