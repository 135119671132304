import React, { useState, useEffect, useRef } from "react";
import Checkbox from "components/checkbox";
import Tooltip from './Tooltip';
import apiClient from "./../axiosInstance";

const ExtraModal = ({ car, extras, onClose }) => {
    const [carExtras, setCarExtras] = useState([]);
    const modalRef = useRef(null); // Ref for modal container

    useEffect(() => {
        if (car && extras) {
            const updatedExtras = extras.map((extra) => {
                const existingExtra = car.Extras.find(
                    (e) => e.ExtraID === Number(extra.ExtraID)
                );
                return existingExtra
                    ? { ...existingExtra, enabled: true }
                    : { ...extra, ExtraPrice: 0, ExtraMaxPrice: 0, enabled: false };
            });

            setCarExtras(updatedExtras);
        }

        // Close the modal when clicking outside
        const handleOutsideClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose(false); // Close the modal if outside is clicked
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick); // Cleanup listener
        };
    }, [car, extras, onClose]);

    const handlePriceChange = (extraId, field, value) => {
        setCarExtras((prevExtras) =>
            prevExtras.map((extra) =>
                extra.ExtraID === extraId
                    ? { ...extra, [field]: parseFloat(value) || 0 }
                    : extra
            )
        );
    };

    const handleExtraToggle = (extra) => {
        setCarExtras((prevExtras) =>
            prevExtras.map((e) =>
                e.ExtraID === extra.ExtraID
                    ? { ...e, enabled: !e.enabled }
                    : e
            )
        );
    };

    const handleSave = async () => {
        try {
            const selectedExtras = carExtras
                .filter((extra) => extra.enabled)
                .map(({ ExtraID, ExtraPrice, ExtraMaxPrice }) => ({
                    ExtraID,
                    Price: ExtraPrice,
                    MaxPrice: ExtraMaxPrice,
                }));

            await apiClient.post(`/update-extras/${car.CarID}`, selectedExtras);
            onClose(true);
        } catch (error) {
            alert("An error occurred while saving. Please try again.");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-30">
            <div ref={modalRef} className="bg-gray-200 dark:bg-navy-900 dark:text-white p-5 rounded shadow-md w-full max-w-lg">
                <h2 className="text-xl font-semibold mb-4">
                    {car.Make} {car.Model} {car.Transmission} {car.FuelType}
                </h2>

                <div className="overflow-x-auto">
                    <table className="table-auto w-full border-collapse">
                        <thead>
                            <tr className="bg-gray-200 dark:bg-navy-800">
                                <th className="px-2 py-1 text-left"></th>
                                <th className="px-2 py-1 text-left">Extra</th>
                                <th className="px-2 py-1 text-left">
                                    Eur<Tooltip
                                        content={
                                            <>
                                                The price is charged per day. <br />
                                                Entering 0 will mark the item as free for promotion.
                                            </>
                                        }
                                        placement="bottom-left"
                                    />
                                </th>
                                <th className="px-2 py-1 text-left">
                                    Max<Tooltip
                                        content={
                                            <>
                                                Max price for all rental days. <br />
                                                Entering 0 will make sure that it has no max.
                                            </>
                                        }
                                        placement="bottom-left"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {carExtras.map((extra) => (
                                <tr key={extra.ExtraID} className="border-b">
                                    <td className="px-2 py-1">
                                        <Checkbox
                                            checked={extra.enabled}
                                            onChange={() => handleExtraToggle(extra)}
                                        />
                                    </td>
                                    <td className="px-2 py-1">{extra.ExtraName}</td>
                                    <td className="px-2 py-1">
                                        <input
                                            type="number"
                                            value={extra.ExtraPrice}
                                            onChange={(e) =>
                                                handlePriceChange(extra.ExtraID, "ExtraPrice", e.target.value)
                                            }
                                            className="w-16 p-1 border rounded dark:bg-navy-800"
                                            disabled={!extra.enabled}
                                        />
                                    </td>
                                    <td className="px-2 py-1">
                                        <input
                                            type="number"
                                            value={extra.ExtraMaxPrice}
                                            onChange={(e) =>
                                                handlePriceChange(extra.ExtraID, "ExtraMaxPrice", e.target.value)
                                            }
                                            className="w-16 p-1 border rounded dark:bg-navy-800"
                                            disabled={!extra.enabled}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSave}
                        className="bg-brand-500 hover:bg-brand-400 text-white px-4 py-1 rounded mr-2"
                    >
                        Save
                    </button>
                    <button
                        onClick={() => onClose(false)}
                        className="bg-red-500 hover:bg-red-400 px-4 py-1 rounded"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExtraModal;
