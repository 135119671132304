export const formatDateWithoutYear = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return "";

    const day = ("0" + date.getDate()).slice(-2);
    const monthName = date.toLocaleString("en-US", { month: "long" });

    return `${day} ${monthName}`;
};

export const formatDateToDDMM = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit' });
    return formatter.format(date);
};

export const handleRemovePriceRow = (carId, index, setCars) => {
    setCars((prevCars) =>
        prevCars.map((car) => {
            if (car.CarID !== carId) return car;

            let newPrices = [...car.prices];

            // Remove the selected row by index
            newPrices.splice(index, 1);

            // If there's a next row, update its StartDate to be +1 day from the previous row's EndDate
            if (index < newPrices.length && index > 0) {
                let prevEndDate = new Date(newPrices[index - 1].EndDate);
                prevEndDate.setDate(prevEndDate.getDate() + 1); // Increment by 1 day

                newPrices[index].StartDate = prevEndDate.toISOString();
            }
            // If there's no next row, update the previous row's EndDate to December 31
            else if (index > 0) {
                newPrices[index - 1].EndDate = new Date("2000-12-31T00:00:00.000Z").toISOString();
            }

            return { ...car, prices: newPrices };
        })
    );
};

export const handlePriceChange = (carId, index, value, setCars) => {
    setCars((prevCars) =>
        prevCars.map((car) => {
            if (car.CarID !== carId) return car;

            let newPrices = [...car.prices];
            let selectedRow = newPrices[index];

            newPrices[index] = {
                ...selectedRow,
                PricePerDay: parseFloat(value) || 0,
            };
            return { ...car, prices: [...newPrices] };
        })
    );
};

export const sortRulesByDate = (rules) => {
    return rules.sort((a, b) => {
        const startDateA = new Date(a.RuleStartDate);
        const startDateB = new Date(b.RuleStartDate);

        if (startDateA < startDateB) return -1;
        if (startDateA > startDateB) return 1;

        const endDateA = new Date(a.RuleEndDate);
        const endDateB = new Date(b.RuleEndDate);

        if (endDateA < endDateB) return -1;
        if (endDateA > endDateB) return 1;

        return 0;
    });
};

export const handleDateChangeLogic = (carId, index, newEndDate, prevCars) => {
    if (!newEndDate || isNaN(newEndDate.getTime())) return prevCars;

    return prevCars.map((car) => {
        if (car.CarID !== carId) return car;

        let updatedPrices = [...car.prices];
        let selectedRow = updatedPrices[index];
        let previousEndDate = new Date(selectedRow.EndDate);

        let selectedEndDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), newEndDate.getDate(), 12, 0, 0);

        let nextIndex = index + 1;
        while ( nextIndex < updatedPrices.length && selectedEndDate.getTime() >= new Date(updatedPrices[nextIndex].EndDate).getTime()) {
            updatedPrices.splice(nextIndex, 1);
        }

        // nextDay for both purposes
        if (nextIndex < updatedPrices.length && selectedEndDate>previousEndDate) {
            let nextDay = new Date(selectedEndDate);
            nextDay.setDate(nextDay.getDate() + 1);
            updatedPrices[nextIndex].StartDate = nextDay.toISOString().split("T")[0];
        }

        const isEndOfYear = selectedEndDate.getMonth() === 11 && selectedEndDate.getDate() === 31;
        if (isEndOfYear) {
            updatedPrices = updatedPrices.slice(0, index + 1); // Cut off anything after December 31st
            updatedPrices[index] = {
                ...selectedRow,
                EndDate: selectedEndDate.toISOString().split("T")[0],
            };
        } 
        else if (selectedEndDate < previousEndDate) {
            let nextDay = new Date(selectedEndDate);
            nextDay.setDate(nextDay.getDate() + 1);

            let newRow = {
                StartDate: nextDay.toISOString().split("T")[0],
                EndDate: previousEndDate.toISOString().split("T")[0],
                PricePerDay: '',
            };

            updatedPrices[index] = {
                ...selectedRow,
                EndDate: selectedEndDate.toISOString().split("T")[0],
            };

            updatedPrices.splice(index + 1, 0, newRow);
        } else {
            updatedPrices[index] = {
                ...selectedRow,
                EndDate: selectedEndDate.toISOString().split("T")[0],
            };
        }

        return { ...car, prices: updatedPrices };
    });
};




export const showModal = (setModalContent, setIsModalOpen, title, content) => {
    setModalContent({
        title,
        content,
        footer: (
            <div className="flex justify-end w-full">
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={() => setIsModalOpen(false)}
                >
                    Close
                </button>
            </div>
        ),
    });
    setIsModalOpen(true);
};