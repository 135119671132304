import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "react-calendar/dist/Calendar.css";
import "assets/css/CustomCalendar.css";

const CustomCalendar = ({ name, value, onChange, disabled, minDate }) => {

    const parseDate = (dateString) => {
        if (!dateString) return null;
        const parts = dateString.split(" ");
        if (parts.length !== 2) return null;

        const day = parseInt(parts[0], 10);
        const monthName = parts[1];

        const monthIndex = new Date(Date.parse(monthName + " 1, 2000")).getMonth();
        return new Date(2000, monthIndex, day);
    };

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value ? parseDate(value) : null);
    const [currentDate, setCurrentDate] = useState(parseDate(value) || new Date(2000, 0, 1));

    const inputRef = useRef(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        setSelectedDate(value ? parseDate(value) : null);
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDateChange = (date) => {
        if (minDate && date < minDate) {
            return; // Prevent setting a date before minDate
        }

        setSelectedDate(date);

        if (onChange) {
            onChange(date);
        }

        setShowCalendar(false);
    };

    //const formatDate = (date) => {
    //    if (!date) return "";
    //    const day = ("0" + date.getDate()).slice(-2);
    //    const monthName = date.toLocaleString("en-US", { month: "long" });
    //    return `${day} ${monthName}`;
    //};

    // Convert Date object to "DD/MM"
    const formatDate = (date) => {
        if (!date) return "";
        const day = ("0" + date.getDate()).slice(-2); // Ensure two-digit day (e.g., '01', '02')
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two-digit month (e.g., '01', '12')
        return `${day}/${month}`; // Return as "DD/MM"
    };

    // Stay in 2000
    const handleActiveStartDateChange = ({ activeStartDate }) => {
        if (activeStartDate.getFullYear() !== 2000) {
            setCurrentDate(new Date(2000, activeStartDate.getMonth(), 1)); 
        } else {
            setCurrentDate(activeStartDate);
        }
    };

    // go to the selected month
    const handleInputClick = () => {
        setShowCalendar(true);
        if (selectedDate) {
            setCurrentDate(new Date(2000, selectedDate.getMonth(), 1));
        }
    };

    return (
        <div className={`relative mt-4`}>
            <input
                ref={inputRef}
                type="text"
                name={name}
                readOnly
                value={formatDate(selectedDate)}
                onClick={handleInputClick}
                className={`w-full p-2 rounded-xl bg-white dark:bg-navy-800
                    !text-black border 
                    ${disabled ? "cursor-default bg-gray-200 dark:bg-navy-900" : "cursor-pointer hover:bg-gray-200 dark:hover:bg-navy-700"}
                `}
                disabled={disabled}
            />

            {showCalendar && (
                <div
                    ref={calendarRef}
                    className="absolute z-50 mt-2 bg-white dark:bg-navy-900 shadow-lg rounded-lg"
                    style={{
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "fit-content",
                    }}
                >
                    <Calendar
                        onChange={handleDateChange}
                        value={selectedDate || new Date(2000, 0, 1)}
                        prevLabel={currentDate.getMonth() === 0 ? null : <MdChevronLeft className="ml-1 h-6 w-6" />}
                        nextLabel={currentDate.getMonth() === 11 ? null : <MdChevronRight className="ml-1 h-6 w-6" />}
                        view="month"
                        showNavigation={true}
                        calendarType="ISO 8601"
                        tileClassName="text-sm font-medium"
                        minDate={minDate || new Date(2000, 0, 1)} // Prevents going before Jan 1, 2000
                        maxDate={new Date(2000, 11, 31)} // Prevents going after Dec 31, 2000
                        formatMonthYear={(locale, date) =>
                            date.toLocaleDateString(locale, { month: "long" }) // Hide year
                        }
                        onActiveStartDateChange={handleActiveStartDateChange}
                        activeStartDate={currentDate}
                    />
                </div>
            )}
        </div>
    );
};

export default CustomCalendar;
