import React from 'react';

// Helper function to determine tooltip placement styles
const getTooltipPlacementClasses = (placement) => {
    switch (placement) {
        case 'top':
            return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
        case 'bottom':
            return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
        case 'left':
            return 'top-1/2 right-full transform -translate-y-1/2 mr-2';
        case 'bottom-left':
            return 'top-1/2 right-0 transform mt-2';
        case 'right':
            return 'top-1/2 left-full transform -translate-y-1/2 ml-2';
        default:
            return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
    }
};

const Tooltip = ({ content, placement = 'bottom' }) => {
    return (
        <div className="relative inline-block group">
            <span
                className="cursor-pointer text-gray-600 hover:text-blue-500 text-sm px-1"
                aria-label={content}
            >
                <i className="fas fa-info-circle"></i>
            </span>

            <div
                className={`absolute z-50 hidden group-hover:flex items-start justify-center 
                            ${getTooltipPlacementClasses(placement)}
                            px-6 py-3 text-sm font-medium text-white bg-gray-800 rounded-md shadow-lg
                            opacity-0 group-hover:opacity-100 transition-opacity duration-200 w-64 text-left`}
            >
                {content}
            </div>
        </div>
    );
};

export default Tooltip;
