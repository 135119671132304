import React, { useState, useRef, useEffect } from "react";
import MiniCalendar from "components/calendar/MiniCalendar";

const DateInputWithCalendar = ({
    label,
    name,
    value,
    onChange,
    error,
    setError,
    className,
}) => {
    const parseDate = (dateString) => {
        if (!dateString) return null;
        const parts = dateString.split("-");
        if (parts.length !== 3) return null;
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[2], 10);
        return new Date(year, month, day);
    };

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value ? parseDate(value) : null);
    const inputRef = useRef(null);
    const calendarRef = useRef(null);

    // Update selectedDate when value prop changes
    useEffect(() => {
        setSelectedDate(value ? parseDate(value) : null);
    }, [value]);

    // Close calendar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDateChange = (date) => {
        const formattedDate = formatDate(date);
        setSelectedDate(date);
        onChange({
            target: { name, value: formattedDate },
        });
        setShowCalendar(false);
        if (error) {
            setError((prevError) => ({
                ...prevError,
                [name]: false,
            }));
        }
    };

    const formatDate = (date) => {
        if (!date) return "";
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    return (
        <div className={`relative mt-4 ${className}`}>
            <label className="mb-2 text-sm font-semibold">
                {label}
                <span className="text-red-500 ml-1">*</span>
            </label>
            <input
                ref={inputRef}
                type="text"
                name={name}
                value={formatDate(selectedDate)}
                onClick={() => setShowCalendar((prev) => !prev)}
                readOnly
                placeholder="Select Date"
                className={`w-full p-2 rounded-xl bg-white dark:bg-navy-800 !text-black border cursor-pointer hover:bg-gray-200 dark:hover:bg-navy-700 ${error ? 'border-red-500' : 'border-gray-300'} placeholder-black`}
            />
            {showCalendar && (
                <div
                    ref={calendarRef}
                    className="absolute z-50 mt-2 bg-white dark:bg-navy-900 shadow-lg rounded-lg"
                    style={{
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "fit-content",
                    }}
                >
                    <MiniCalendar value={selectedDate || new Date()} onChange={handleDateChange} />
                </div>
            )}
            {error && <span className="text-red-500 text-sm">{error}</span>}
        </div>
    );
};

export default DateInputWithCalendar;
