import React, { useEffect, useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import apiClient from "../axiosInstance";
import Modal from "components/Modal/Modal";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Button from 'components/button/Button';
import { formatDateWithoutYear, handleRemovePriceRow, handlePriceChange, handleDateChangeLogic, formatDateToDDMM, sortRulesByDate,  showModal } from './helper.js';
import CustomCalendar from 'components/calendar/CustomCalendar';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const PricesOverview = () => {
    const [cars, setCars] = useState([]);
    const [, setRules] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [tableData, setTableData] = useState([]);
    const [minDaysColumns, setMinDaysColumns] = useState([]);
    const [expandedCars, setExpandedCars] = useState({});

    const toggleCarRules = (carId) => {
        setExpandedCars((prev) => ({
            ...prev,
            [carId]: !prev[carId], // Toggle state
        }));
    };

    useEffect(() => {
        const fetchCarsAndPrices = async () => {
            try {
                const response = await apiClient.get("/cars-and-prices");
                let carsData = response.data;

                carsData = carsData.map((car) => ({
                    ...car,
                    prices: car.prices.length > 0
                        ? car.prices.map((priceRow) => ({
                            ...priceRow,
                            StartDate: priceRow.StartDate ? priceRow.StartDate : "2000-01-01T00:00:00.000Z",
                            EndDate: priceRow.EndDate ? priceRow.EndDate : "2000-12-31T00:00:00.000Z",
                        }))
                        : [{
                            StartDate: "2000-01-01T00:00:00.000Z",
                            EndDate: "2000-12-31T00:00:00.000Z",
                            PricePerDay: '',
                        }],
                }));

                setCars(carsData);

                const extractedRules = carsData.flatMap((car) =>
                    car.priceRules.map((rule) => ({
                        CarID: car.CarID,
                        CarPriceRuleID: rule.CarPriceRuleID,
                        RuleStartDate: rule.RuleStartDate,
                        RuleEndDate: rule.RuleEndDate,
                        MinDays: rule.MinDays,
                        NewPricePerDay: rule.NewPricePerDay,
                    }))
                );
                const sortedRules = sortRulesByDate(extractedRules);

                setRules(extractedRules);

                const groupedDateRanges = {};

                // Group the sorted rules by formatted date range
                sortedRules.forEach((rule) => {
                    const key = `${formatDateToDDMM(rule.RuleStartDate)}-${formatDateToDDMM(rule.RuleEndDate)}`;
                    if (!groupedDateRanges[key]) {
                        groupedDateRanges[key] = [];
                    }
                    groupedDateRanges[key].push(rule);
                });


                // Extract unique MinDays values
                const minDaysArray = Array.from(
                    new Set(sortedRules.map((rule) => rule.MinDays))
                ).sort((a, b) => a - b); // Sorting from min to max


                setMinDaysColumns(minDaysArray); // Update state with the minDays columns

                const allDateRanges = Object.keys(groupedDateRanges);
                const tableData = [];

                allDateRanges.forEach((dateRange) => {
                    const row = { dateRange };

                    minDaysArray.forEach((minDays) => {
                        carsData.forEach((car) => {
                            const rule = groupedDateRanges[dateRange].find(
                                (r) =>
                                    r.CarID === car.CarID &&
                                    r.MinDays === minDays
                            );
                            if (rule) {
                                row[`${car.CarID}-${minDays}`] = rule.NewPricePerDay;
                            } else {
                                row[`${car.CarID}-${minDays}`] = "-";
                            }
                        });
                    });

                    tableData.push(row);
                });

                setTableData(tableData);
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchCarsAndPrices();
    }, []);



    const handlesave = async (selectedCarID, cars) => {
        let selectedCar = cars.find((car) => car.CarID === selectedCarID);

        let priceUpdates = selectedCar.prices.map((price) => ({
            StartDate: new Date(price.StartDate).toISOString().split("T")[0],
            EndDate: new Date(price.EndDate).toISOString().split("T")[0],
            PricePerDay: price.PricePerDay,
        }));

        try {
            const response = await apiClient.post(`/update-prices/${selectedCarID}`, {
                updates: priceUpdates,
            });

            if (response.status === 200) {
                showModal(setModalContent, setIsModalOpen, "Success",
                    <div className="flex flex-col items-center">
                        <CheckCircleIcon className="h-12 w-12 text-green-500 mb-4" />
                        <p>Prices updated successfully.</p>
                    </div>
                );
            } else {
                showModal(setModalContent, setIsModalOpen,"Error",
                    <div className="flex flex-col items-center">
                        <XMarkIcon className="h-12 w-12 text-red-500 mb-4" />
                        <p>Failed to update prices. Please try again.</p>
                    </div>
                );
            }
        } catch (error) {
            showModal(setModalContent, setIsModalOpen, "Error",
                <div className="flex flex-col items-center">
                    <XMarkIcon className="h-12 w-12 text-red-500 mb-4" />
                    <p>Failed to update prices. Please try again.</p>
                </div>
            );
        }
    };

    const handleDateChange = (carId, index, newEndDate) => {
        if (!newEndDate || isNaN(newEndDate.getTime())) return;

        setCars((prevCars) => handleDateChangeLogic(carId, index, newEndDate, prevCars));
    };

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="mt-5 grid grid-cols-1 gap-5">
                {cars.map((car) => (
                    <Card key={car.CarID} extra="p-5 mb-1 flex h-auto">
                        <div className="flex flex-col md:flex-row w-full">

                            {/* Left Side: Car Info / Image */}
                            <div className="w-1/4 md:w-1/4 h-auto flex-shrink-0 overflow-hidden mb-4 md:mb-0">
                                <div className="flex flex-col items-center">
                                    <span className="block font-bold text-center">
                                        {car.Make} {car.Model}
                                    </span>
                                    <span className="text-center">
                                        {car.Transmission} {car.FuelType}
                                    </span>
                                </div>
                                <img
                                    src={car.imageURL}
                                    alt={`${car.Make} ${car.Model}`}
                                    className="w-full h-auto"
                                    style={{ objectFit: "contain" }}
                                />
                            </div>

                            <div className="w-full md:w-1/2 h-full flex flex-col md:items-start">
                                {car.prices.length > 0 && (
                                    <table className="w-full table-auto">
                                        <thead>
                                            <tr>
                                                <th className="text-left">From</th>
                                                <th className="text-left">Until</th>
                                                <th className="text-left">Price</th>
                                                <th className="text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {car.prices.map((priceRow, index) => {
                                                const { StartDate, EndDate, PricePerDay } = priceRow;

                                                return (
                                                    <tr key={`row-${index}-${StartDate}`}>
                                                        <td className="p-1">
                                                            <CustomCalendar
                                                                extra="gray-100"
                                                                value={formatDateWithoutYear(StartDate)}
                                                                disabled={true}
                                                            />
                                                        </td>
                                                        <td className="p-1">
                                                            <CustomCalendar
                                                                minDate={new Date(StartDate)}
                                                                maxDate={new Date(EndDate) >= new Date(StartDate) ? new Date(EndDate) : new Date("2000-12-31")}
                                                                value={formatDateWithoutYear(EndDate)}
                                                                onChange={(date) => handleDateChange(car.CarID, index, date)}
                                                            />
                                                        </td>
                                                        <td className="p-1">
                                                            <InputField
                                                                extra="w-full"
                                                                type="number"
                                                                step="0.01"
                                                                value={PricePerDay ?? 0}
                                                                onChange={(e) => handlePriceChange(car.CarID, index, e.target.value, setCars)}
                                                            />
                                                        </td>
                                                        <td className="w-3">
                                                            {index !== 0 && (
                                                                <button
                                                                    onClick={() => handleRemovePriceRow(car.CarID, index, setCars)}
                                                                    className="text-red-500 hover:text-red-700 transition-all mt-4"
                                                                >
                                                                    <XMarkIcon className="h-6 w-6" />
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                )}

                                {car.prices.length > 0 && (
                                    <div className="flex justify-between items-center w-full mt-4">
                                        <Button
                                            label="Save Prices"
                                            className="bg-brand-500 text-white px-4 py-2 rounded-lg
                                                hover:bg-brand-600 shadow-lg hover:shadow-xl transition-all
                                                duration-300 ease-in-out focus:outline-none
                                                focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                                            onClick={() => handlesave(car.CarID, cars)}
                                        />
                                    </div>
                                )}
                            </div>





                            <div>
                                <div
                                    onClick={() => toggleCarRules(car.CarID)}
                                    className="cursor-pointer flex items-center justify-between bg-gray-200 dark:bg-navy-700 p-4 rounded-lg"
                                >
                                    <h3 className="text-lg font-semibold">{car.Make} {car.Model} Discounts</h3>
                                    {expandedCars[car.CarID] ? (
                                        <ChevronUpIcon className="w-6 h-6 text-gray-600" />
                                    ) : (
                                        <ChevronDownIcon className="w-6 h-6 text-gray-600" />
                                    )}
                                </div>
                                {expandedCars[car.CarID] && (
                                    <table className="w-full table-auto border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="text-left px-8">Date Range</th>
                                               {minDaysColumns.map((minDays, colIndex) => (
                                                    <th key={colIndex} className="px-1 py-1 text-center">{`${minDays} Days`}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData
                                                // 1) still must have columns for this car
                                                .filter((row) =>
                                                    Object.keys(row).some((key) => key.startsWith(`${car.CarID}-`))
                                                )
                                                // 2) but also require at least one column that is not "-"
                                                .filter((row) =>
                                                    minDaysColumns.some((minDays) => row[`${car.CarID}-${minDays}`] !== "-")
                                                )
                                                .map((row, rowIndex) => (
                                                    <tr key={rowIndex} className="border-t border-gray-300">
                                                        <td>{row.dateRange}</td>
                                                        {minDaysColumns.map((minDays, colIndex) => (
                                                            <td key={colIndex} className="text-sm text-center">
                                                                {row[`${car.CarID}-${minDays}`] !== undefined &&
                                                                    row[`${car.CarID}-${minDays}`] !== "-"
                                                                    ? row[`${car.CarID}-${minDays}`]
                                                                    : "-"}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}

                                        </tbody>
                                    </table>
                                )}
                                </div>
                        </div>
                       




                    </Card>
                ))
                }
            </div>
         
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
            />
        </div>

    );
};

export default PricesOverview;