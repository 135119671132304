import React from "react";

import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";

import MainDashboard from "views/admin/dashboard";
import DataTables from "views/admin/reservations";
import EditReservation from "views/admin/reservations/edit";
import MakeReservation from "views/admin/makereservation";
import Cars from "views/admin/cars";
import CarsOverview from "views/admin/caroverview";
import CarPrices from "views/admin/carprices";
import Locations from "views/admin/locations";
import Profile from "views/admin/profile";
import Settings from "views/admin/settings";
import StationDetails from "views/admin/locations/StationDetails";
import ExtrasPrices from "views/admin/extras";

import { MdHome, MdAttachMoney, MdBarChart, MdDirectionsCar, MdEvent, MdCalendarMonth, MdLocationOn, MdAddShoppingCart } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

const routes = [
  {
        name: "Dashboard",
        layout: "/admin",
        path: "dashboard",
        icon: <MdHome className="h-6 w-6" />,
        component: <MainDashboard />,
  },
  {
        name: "Reservations",
        layout: "/admin",
        icon: <MdBarChart className="h-6 w-6" />,
        path: "reservations",
        component: <DataTables />,
    },
    {
        name: "Reservations Edit",
        layout: "/admin",
        path: "reservations/:id",
        component: <EditReservation />,
        sidebar: false,
    },
    {
        name: "Make Reservation",
        layout: "/admin",
        icon: <MdEvent className="h-6 w-6" />,
        path: "makereservation",
        component: <MakeReservation />,
    },
    {
        name: "Cars",
        layout: "/admin",
        path: "cars",
        icon: <MdDirectionsCar className="h-6 w-6" />,
        component: <Cars />,
        sidebar: true,
    },
    {
        name: "Extras",
        layout: "/admin",
        path: "extras",
        icon: <MdAddShoppingCart className="h-6 w-6" />,
        component: <ExtrasPrices />,
        sidebar: true,
    },
    {
        name: "Cars Overview",
        layout: "/admin",
        path: "caroverview",
        icon: <MdCalendarMonth className="h-6 w-6" />,
        component: <CarsOverview />,
        sidebar: true,
    },
    {
        name: "Prices",
        layout: "/admin",
        path: "carprices",
        icon: <MdAttachMoney className="h-6 w-6" />,
        component: <CarPrices />,
        sidebar: true,
    },
    {
        name: "Locations",
        layout: "/admin",
        path: "locations",
        icon: <MdLocationOn className="h-6 w-6" />,
        component: <Locations />,
        sidebar: true,
    },
    {
        name: "Rental Settings",
        layout: "/admin",
        path: "profile",
        icon: <IoMdSettings className="h-5 w-5" />,
        component: <Profile />,
        sidebar: true,
    },
    {
        name: "signin",
        layout: "/auth",
        path: "sign-in",
        component: <SignIn />,
        sidebar: false,
    }, 
    {
        name: "Forgot Password",
        layout: "/auth",
        path: "forgot-password",
        component: <ForgotPassword />,
        sidebar: false,
    },
    {
        name: "Reset Pasword",
        layout: "/auth",
        path: "reset-password",
        component: <ResetPassword />,
        sidebar: false,
    },
    {
        name: "Settings",
        layout: "/admin",
        path: "settings",
        component: <Settings/>,
        sidebar: false,
    },
    {
        name: "Station Details",
        layout: "/admin",
        path: "locations/:id",
        component: <StationDetails />,
        sidebar: false,
    },
];
export default routes;
