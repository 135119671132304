import React from "react";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
const MiniCalendar = ({ value, onChange }) => {
    return (
        <div className="p-2">
            <Calendar
                onChange={onChange}
                value={value}
                prevLabel={<MdChevronLeft className="ml-1 h-6 w-6" />}
                nextLabel={<MdChevronRight className="ml-1 h-6 w-6" />}
                view={"month"}
                showNavigation={true}
                calendarType="ISO 8601"
                tileClassName="text-sm font-medium"
            />
        </div>
    );
};
export default MiniCalendar;