import React, { useEffect, useState } from "react";
import apiClient from "./../axiosInstance";
import Card from "components/card";
import ExtraModal from "./ExtraModal";

const ExtraPrices = () => {
    const [cars, setCars] = useState([]);
    const [allExtras, setAllExtras] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const fetchData = async () => {
        try {
            const response = await apiClient.get("/extras-with-prices");

            const carsWithExtras = response.data.Cars.map((car) => ({
                ...car,
                Extras: car.Extras ? car.Extras : [],
            }));

            setAllExtras(response.data.Extras || []);
            setCars(carsWithExtras);
        } catch (err) {
            setError("Failed to load data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdateExtras = (carId, updatedExtras) => {
        setCars((prevCars) =>
            prevCars.map((car) =>
                car.CarID === carId ? { ...car, Extras: updatedExtras } : car
            )
        );
    };

    const handleOpenModal = (car) => {
        setSelectedCar(car);
        setShowModal(true);
    };

    const handleCloseModal = (shouldRefresh) => {
        setSelectedCar(null);
        setShowModal(false);
        if (shouldRefresh) {
            fetchData();
        }
    };



    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return (
            <div className="text-red-500 flex justify-center items-center h-screen">
                {error}
            </div>
        );
    }

    return (
        <div className="flex w-full flex-col gap-5">
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md z-40"></div>
            )}
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                {cars.map((car) => (
                    <Card key={car.CarID} extra="p-5 mb-1 flex h-auto">
                        <div className="flex flex-col md:flex-row w-full">
                            {/* Left Side: Image & Basic Info */}
                            <div className="w-full md:w-1/2 h-auto flex-shrink-0 overflow-hidden">
                                <img
                                    src={car.imageURL}
                                    alt={`${car.Make} ${car.Model}`}
                                    className="w-full h-auto"
                                    style={{ objectFit: "contain" }}
                                />
                                <div className="text-center mb-2">
                                    <span className="block font-bold text-lg">
                                        {car.Make} {car.Model}
                                    </span>
                                    <span className="block mb-2">
                                        {car.Transmission} | {car.FuelType}
                                        {car.CompanyName && (
                                            <span className="block text-gray-600 italic">
                                                Company: {car.CompanyName}
                                            </span>
                                        )}
                                    </span>
                                    <button
                                        onClick={() => handleOpenModal(car)}
                                        className="bg-brand-500 hover:bg-brand-400 text-white px-3 py-1 rounded"
                                    >
                                        Add / Edit
                                    </button>
                                </div>
                            </div>

                            {/* Right Side: Extras Table */}
                            <div className="flex flex-col w-full md:w-1/2 p-5">
                                {car.Extras.length === 0 ? (
                                    <div className="text-gray-500">No extras for this car</div>
                                ) : (
                                    <table className="table-auto w-full border-collapse">
                                        <thead>
                                            <tr className="bg-gray-100 dark:bg-navy-900">
                                                <th className="px-2 py-1 text-left">Eur</th>
                                                <th className="px-2 py-1 text-left">Max</th>
                                                <th className="px-2 py-1 text-left">Extra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {car.Extras.map((extra) => (
                                                <tr key={extra.ExtraID} className="border-b text-sm">
                                                    <td className="px-2 py-1">{extra.ExtraPrice}</td>
                                                    <td className="px-2 py-1">{extra.ExtraMaxPrice}</td>
                                                    <td className="px-2 py-1">{extra.ExtraName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </Card>
                ))}
            </div>

            {/* Modal Component */}
            {showModal && (
                <ExtraModal
                    car={selectedCar}
                    extras={allExtras}
                    onClose={handleCloseModal}
                    onUpdate={handleUpdateExtras}
                />
            )}
        </div>
    );
};

export default ExtraPrices;
